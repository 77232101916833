@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* FONTS */
  /* Roboto */
  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Regular.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Light.ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Bold.ttf');
    font-weight: 700;
  }

  /* Raleway */
  @font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/raleway/Raleway-Regular.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/raleway/Raleway-Light.ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/raleway/Raleway-Medium.ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Raleway';
    src: url('assets/fonts/raleway/Raleway-Bold.ttf');
    font-weight: 700;
  }
}

/* BACKGROUND VARIANT SPECIFIC OVERRIDES */
/* See ESuluContentBlockBackgroundVariants in EBackendData.ts */
/* Case "default" (background_variant = null) */
.theme-default {
  @apply bg-transparent;
}
/* Case "primary" */
.theme-primary {
  @apply bg-primary;
}
.theme-primary .text-primary,
.theme-primary_gradient .text-primary {
  @apply text-primary-lightest;
}
.theme-primary .text-primary-text,
.theme-primary_gradient .text-primary-text {
  @apply text-white;
}

/* Case "primary_gradient" */
.theme-primary_gradient {
  @apply bg-gradient-to-t from-primary to-[#0FC2BC];
}

/* Case "light" */
.theme-light {
  @apply bg-primary-light;
}
/* Case "lighter" */
.theme-lighter {
  @apply bg-primary-lighter;
}

/* Case "lightest" */
.theme-lightest {
  @apply bg-primary-lightest;
}

/* CUSTOM STYLES */
.blockOuter {
  @apply py-20;
}
.page-header + .blockOuter.text,
.blockOuter.teamwork {
  @apply pt-16;
}
.page-header + .blockOuter.theme-default {
  @apply bg-white;
}
.page-header + .blockOuter.text .blockInner > div > h2,
.blockOuter.teamwork .blockInner > div > h2 {
  @apply mt-3;
}
.page-header + .blockOuter.split_content.has-title {
  @apply pt-20 !important;
}
.blockOuter.split_content.has-title .blockOuter {
  @apply pt-6;
}
.blockOuter.split_content.has-title img {
  @apply mt-2.5;
}
.blockOuter.split_content .left-part .blockOuter:last-child {
  @apply pb-7 lg:pb-20;
}
.blockOuter.split_content .left-part .blockOuter.card:last-child {
  @apply pb-14 lg:pb-24;
}
.blockOuter.split_content .right-part .blockOuter:first-child {
  @apply pt-7 lg:pt-20;
}
.blockOuter.split_content .right-part .blockOuter.card:first-child {
  @apply pt-12 lg:pt-24;
}
.blockOuter.split_content .left-part .blockOuter.image_with_link:first-child,
.blockOuter.split_content .right-part .blockOuter.image_with_link:first-child {
  @apply pt-24;
}
.blockOuter.split_content
.left-part
.blockOuter.image_with_link:first-child
> .blockInner:not(.has-fullwidth-image)
> img,
.blockOuter.split_content
.left-part
.blockOuter.image_with_link:first-child
> .blockInner:not(.has-fullwidth-image)
> a,
.blockOuter.split_content
.right-part
.blockOuter.image_with_link:first-child
> .blockInner:not(.has-fullwidth-image)
> img,
.blockOuter.split_content
.right-part
.blockOuter.image_with_link:first-child
> .blockInner:not(.has-fullwidth-image)
> a {
  @apply w-1/2;
}
.blockOuter.split_content.has-only-image-on-right-side
.right-part
.blockOuter.image_with_link:first-child {
  @apply pt-5;
}
.blockOuter.split_content .blockOuter.image_with_link:last-child {
  @apply pb-24;
}
.blockOuter.split_content .blockOuter.card,
.blockOuter.card_slider,
.blockOuter.slider_selector,
.blockOuter.icon_card_block {
  @apply py-24;
}
.blockOuter.card_slider.has-title,
.blockOuter.slider_selector.has-title,
.blockOuter.icon_card_block.has-title {
  @apply pt-20;
}
.blockOuter.card_slider + .blockOuter.card_slider {
  @apply pt-0;
}
.blockOuter.teamwork {
  @apply pb-24 lg:pb-20;
}
.blockOuter.text + .blockOuter.image_with_link,
.blockOuter.image_with_link + .blockOuter.text {
  @apply pt-0 -mt-11;
}
.blockOuter.image_with_link
+ .blockOuter.text
.blockInner
> div
> h3:first-child {
  @apply -mt-12;
}
.blockOuter.text .buttonLink {
  @apply block !w-auto min-w-0 mx-auto;
}
.blockOuter.text .buttonLink > a {
  @apply px-8 py-2;
}
.blockOuter.theme-primary.text .buttonLink > a,
.blockOuter.theme-primary_gradient.text .buttonLink > a {
  @apply bg-white border-primary-lighter hover:bg-transparent hover:text-primary-lighter hover:border-primary-lighter;
  color: theme(
  colors.primary.DEFAULT
  ); /* cannot use tailwind class 'text-primary' here because of circular dependencies */
}
.blockOuter.text .buttonLink > a:hover {
  @apply bg-transparent text-primary border-primary;
}
.blockInner {
  @apply w-full m-auto px-10 lg:px-20;
}
.page-header + .blockOuter > .blockInner,
.blockInner.narrowWidth,
.narrowWidth {
  @apply max-w-6xl;
}
.narrowWidth {
  @apply mx-auto;
}
.page-header + .blockOuter.split_content > .blockInner {
  @apply max-w-6xl !important;
}

.blockInner.fullWidth {
  @apply max-w-none px-0;
}

.blockInner > div > h2:first-child,
.blockInner > div > h3:first-child {
  @apply mt-1;
}
.blockInner > div > p:last-child {
  @apply mb-2;
}
.blockInner > div > p + p {
  @apply mt-6;
}

html {
  font-family: Roboto, serif;
}

h1 {
  @apply text-h1 text-primary-text pb-6;
}
h2 {
  @apply text-h2 md:leading-snug font-light text-primary-text font-raleway;
}
h3,
h4 {
  @apply text-2xl font-medium text-primary-text;
}
h5 {
  @apply text-h5 leading-7 text-primary-text mt-[1rem];
}
p,
li {
  @apply text-base leading-7 font-light text-primary-text;
}

h1 + p,
h2 + p,
h4 + p,
h5 + p {
  @apply mt-6;
}

h3 + p {
  @apply mt-5;
}

h1 + h2,
h1 + h3,
h2 + h3,
h3 + h4,
h4 + h5 {
  @apply mt-8;
}

a {
  @apply text-base leading-7 font-light text-primary no-underline hover:text-primary-hover transition-colors ease-in-out duration-300;
}

ul {
  @apply list-disc ml-7;
}
ol {
  @apply list-decimal ml-7;
}

* + ul,
* + ol {
  @apply mt-4;
}

b,
strong {
  @apply font-bold;
}

.button-hive {
  @apply bg-hive-blue hover:bg-hive-blue-hover;
}

.button-intrexx {
  @apply text-intrexx-blue bg-intrexx-yellow hover:bg-intrexx-yellow-hover;
}

/* RIPPLE EFFECT */
.ripple {
  @apply absolute block w-5 h-5 bg-primary rounded-full opacity-100;
  animation: 1s ease-out 1 forwards ripple-effect;
}

.klaro .cookie-modal .cm-btn.cm-btn-danger,
.klaro .context-notice .cm-btn.cm-btn-danger,
.klaro .cookie-notice .cm-btn.cm-btn-danger {
  background-color: var(--primary);
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  z-index: 9999 !important;
}

.leaflet-pane {
  z-index: auto;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(15);
    opacity: 0.375;
  }
  100% {
    transform: scale(50);
    opacity: 0;
  }
}

form [type='submit'] {
  @apply cursor-pointer pointer-events-auto;
}

form:invalid [type='submit'] {
  @apply bg-[#7BC5CD] cursor-no-drop pointer-events-none;
}
